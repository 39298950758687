

<template>
  <CRow id="summary" class="no-gutters">
    <CCol>
      <CCard class="h-100">
        <CCardHeader class="pt-1 pb-0 mb-0 card-header">
          <div id="branch-name"> Branch {{ branchName }} </div>
          <div id="range-from-to"> 
            From 
            <span class="date"> {{startDateString}} </span> 
            to 
            <span class="date">{{endDateString}} </span> 
          </div>
        </CCardHeader>
        <CCardBody class="card-body">
          <CRow> 
            <CCol>
              <div id="within-range"> Within the 
                <span class="number"> {{dateDiff}} </span> days 
              </div>
              <div id="active-day">
                <span class="number"> {{deviceIds.length}} </span>
                <label> Total Active Trucks </label>
              </div>
              <div id="job-count">
                <span class="number"> {{jobCounts}} </span>
                <label> Jobs in Total </label>
              </div>
              <div id="bad-trucks" hidden>
                <span class="number"> {{badTruckCount}} </span>
                <label> Trucks have a sub-par safety score </label>
              </div>
              <CScoreCard
                class="p-0"
                score="good"
                title="Safety Score"
              /> <!-- h_todo fix the scorecard-->
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>


<script>
import { BranchSummary } from '@/lib/rule_lib/branch_summary';
import CScoreCard from '@/components/reports/CScoreCard.vue';
export default {
  name: "SummaryReportScopeBranch",
  components: {
    CScoreCard
  },
  props: {
    summary: BranchSummary,
  },
  data() {
    return {
      
    };
  },
  computed:{
    branchName () {
      let branch = this.$store.getters.selectedBranch;
      if (branch) {
        return branch.name;
      }
      return "";
    },

    deviceIds() {
      return this.summary.deviceIds;
    },
    
    // truckIds() {
    //   return this.summary.truckIds;
    // },

    jobCounts() {
      return this.summary.jobCounts;
    },

    startDateString () {
      return new Date(this.summary.startTimestamp).toDateString();
    },

    endDateString () {
      return new Date(this.summary.endTimestamp).toDateString();
    },
    
    dateDiff () {
      return Math.round((this.summary.endTimestamp - this.summary.startTimestamp) / (1000*60*60*24));
    },

    badTruckCount () {
      return this.summary.badTruckCount || 3; // h_todo
    }
  },
  methods:{
  }
};
</script>

<style scoped>
#summary .card-header #branch-name {
  font-size: 30px;
  font-weight: 700;
}

#summary .card-body .number {
  font-size: 25px;
}

#summary .card-header .date {
  font-weight: 500;
}





</style>