var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "no-gutters", attrs: { id: "summary" } },
    [
      _c(
        "CCol",
        [
          _c(
            "CCard",
            { staticClass: "h-100" },
            [
              _c("CCardHeader", { staticClass: "pt-1 pb-0 mb-0 card-header" }, [
                _c("div", { attrs: { id: "branch-name" } }, [
                  _vm._v(" Branch " + _vm._s(_vm.branchName) + " ")
                ]),
                _c("div", { attrs: { id: "range-from-to" } }, [
                  _vm._v(" From "),
                  _c("span", { staticClass: "date" }, [
                    _vm._v(" " + _vm._s(_vm.startDateString) + " ")
                  ]),
                  _vm._v(" to "),
                  _c("span", { staticClass: "date" }, [
                    _vm._v(_vm._s(_vm.endDateString) + " ")
                  ])
                ])
              ]),
              _c(
                "CCardBody",
                { staticClass: "card-body" },
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        [
                          _c("div", { attrs: { id: "within-range" } }, [
                            _vm._v(" Within the "),
                            _c("span", { staticClass: "number" }, [
                              _vm._v(" " + _vm._s(_vm.dateDiff) + " ")
                            ]),
                            _vm._v(" days ")
                          ]),
                          _c("div", { attrs: { id: "active-day" } }, [
                            _c("span", { staticClass: "number" }, [
                              _vm._v(" " + _vm._s(_vm.deviceIds.length) + " ")
                            ]),
                            _c("label", [_vm._v(" Total Active Trucks ")])
                          ]),
                          _c("div", { attrs: { id: "job-count" } }, [
                            _c("span", { staticClass: "number" }, [
                              _vm._v(" " + _vm._s(_vm.jobCounts) + " ")
                            ]),
                            _c("label", [_vm._v(" Jobs in Total ")])
                          ]),
                          _c(
                            "div",
                            { attrs: { id: "bad-trucks", hidden: "" } },
                            [
                              _c("span", { staticClass: "number" }, [
                                _vm._v(" " + _vm._s(_vm.badTruckCount) + " ")
                              ]),
                              _c("label", [
                                _vm._v(" Trucks have a sub-par safety score ")
                              ])
                            ]
                          ),
                          _c("CScoreCard", {
                            staticClass: "p-0",
                            attrs: { score: "good", title: "Safety Score" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }