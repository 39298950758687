class TruckSummary {
  constructor(
    deviceId,
    truckId,
    jobOperations, 
    startTimestamp, 
    endTimestamp
  ) {
    this.deviceId = deviceId;
    this.truckId = truckId;

    this.jobTimestamps = jobOperations.map(jobOperation => {
      return jobOperation.startTimestamp;
    })

    this.jobCounts = jobOperations.length;
    // this.violationCounts = jobOperations.reduce((acc, jobOperation) => {
    //   return acc + 1; // h_todo: change 1 to job_violation
    // }, 0);

    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
  }

  // custom
  get activeDates() {
    return [...new Set(
      this.jobTimestamps.map(timestamp => {
        return new Date(timestamp).toDateString();
      })
    )].map(dateString => {
      return new Date(dateString);
    })
  }
}

export { TruckSummary };
