var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "datetime-container mb-1 ml-1" },
    [
      _c("v-date-picker", {
        ref: "datePicker",
        attrs: {
          mode: "date",
          columns: _vm.$screens({ default: 1, sm: 2 }),
          step: 1,
          "max-date": _vm.maxDate,
          "is-range": ""
        },
        on: { input: _vm.onChange },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var inputValue = ref.inputValue
              var inputEvents = ref.inputEvents
              return [
                _c("div", { staticClass: "flex justify-center items-center" }, [
                  _vm._v(" From: "),
                  _c(
                    "input",
                    _vm._g(
                      {
                        staticClass:
                          "px-2 py-1 w-32 rounded disabled focus:outline-none focus:border-indigo-300",
                        attrs: { size: "" },
                        domProps: { value: inputValue.start },
                        on: { change: _vm.setDateFilter }
                      },
                      inputEvents.start
                    )
                  ),
                  _vm._v(" To: "),
                  _c(
                    "input",
                    _vm._g(
                      {
                        staticClass:
                          "px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300",
                        attrs: { size: "sm" },
                        domProps: { value: inputValue.end },
                        on: {
                          changed: function(e) {
                            return _vm.setDateFilter(e, "end")
                          }
                        }
                      },
                      inputEvents.end
                    )
                  )
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.range,
          callback: function($$v) {
            _vm.range = $$v
          },
          expression: "range"
        }
      }),
      _vm._t("button", [
        _vm.button
          ? _c(
              "CButton",
              {
                directives: [
                  {
                    name: "c-tooltip",
                    rawName: "v-c-tooltip",
                    value: "Refresh Transactions",
                    expression: "'Refresh Transactions'"
                  }
                ],
                staticClass: "m-0 p-1",
                attrs: { color: "info", square: "", size: "sm" },
                on: {
                  click: function($event) {
                    return _vm.update()
                  }
                }
              },
              [
                _c(
                  "div",
                  {},
                  [
                    _c("CIcon", {
                      staticClass: "h-100 m-0 p-0",
                      attrs: { name: "cil-sync" }
                    }),
                    _c("span", { staticClass: "color:white pull-right" })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }