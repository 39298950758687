<template>
  <CCardBody>
    <CDataTable
      :items="tableItems"
      :fields="fields"
      :items-per-page="20"
      hover
      pagination
      :table-filter='{ external: false, lazy: false, label: "Search", placeholder: searchPlaceHolder }'
      cleaner
      clickable-rows      
    >
      <template #id="{ item }">
        <ReportPerTruckScopeBranchCard 
          :summary="item"
          @changeToTruckView="changeToTruckView"
        />
      </template>
    </CDataTable>
  </CCardBody>
</template>

<script>

import ReportPerTruckScopeBranchCard from '@/components/reports/ReportPerTruckScopeBranchCard';

export default {
  name: "ReportTableScopeBranch",
  components:{
    ReportPerTruckScopeBranchCard,
  },
  props: {
    summaries: Object,
  },
  data() {
    return {
      fields: [
        {key: 'id', label: ''},
      ],
    };
  },
  computed: {
    tableItems() { // Array
      return Object.values(this.summaries);
    },

    searchPlaceHolder () {
      return "Search For a Truck"
    }
  },
  methods: {
    changeToTruckView(id) {
      this.$emit("changeToTruckView", id);
    },
  },
};
</script>