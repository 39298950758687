var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    { staticClass: "my-1 pt-1 mx-1", attrs: { id: "card-per-truck" } },
    [
      _c(
        "CCardBody",
        { staticClass: "mt-0 pt-1" },
        [
          _c(
            "CRow",
            [
              _c("CCol", { attrs: { col: "3" } }, [
                _c(
                  "td",
                  [
                    _c("TruckInfoCard", {
                      attrs: {
                        device: _vm.deviceId,
                        name: "",
                        showStatus: false
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("CCol", { attrs: { col: "4" } }, [
                _c("div", { attrs: { id: "active-day" } }, [
                  _c("span", [
                    _c("span", { staticClass: "number" }, [
                      _vm._v(" " + _vm._s(_vm.activeDates.length) + " ")
                    ]),
                    _vm._v(" Active Days ")
                  ])
                ]),
                _c("div", { attrs: { id: "job-count" } }, [
                  _c("label", [_vm._v(" Jobs Completed: ")]),
                  _c("span", { staticClass: "number" }, [
                    _vm._v(" " + _vm._s(_vm.jobCounts) + " ")
                  ])
                ]),
                _c("div", { attrs: { id: "violation-count", hidden: "" } }, [
                  _c("label", [_vm._v(" Total # of Violations: ")]),
                  _c("span", { staticClass: "number" }, [
                    _vm._v(" " + _vm._s(_vm.violationCounts) + " ")
                  ])
                ])
              ]),
              _c(
                "CCol",
                { attrs: { col: "3" } },
                [
                  _c("CScoreCard", {
                    staticClass: "p-0",
                    attrs: { score: "good", title: "Safety Score" }
                  })
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { col: "2" } },
                [
                  _c(
                    "CButton",
                    {
                      attrs: { size: "", color: "info" },
                      on: { click: _vm.changeToTruckView }
                    },
                    [_vm._v(" Go to Truck " + _vm._s(_vm.deviceId) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }