class BranchSummary {
  constructor(jobOperations, branchId, startTimestamp, endTimestamp) {
    this.branchId = branchId;
    this.truckIds = [...new Set(jobOperations.map(jobOperation => {
      return jobOperation.truckId;
    }))]

    this.deviceIds = [...new Set(jobOperations.map(jobOperation => {
      return jobOperation.deviceId;
    }))]

    this.jobTimestamps = jobOperations.map(jobOperation => {
      return jobOperation.startTimestamp;
    })

    this.jobCounts = jobOperations.length;
    // this.violationCounts = jobOperations.reduce((acc, jobOperation) => {
    //   return acc + 1; // h_todo: change 1 to job_violation
    // }, 0);

    this.startTimestamp = startTimestamp;
    this.endTimestamp = endTimestamp;
  }

  // custom
  get activeDates() {
    return [...new Set(
      this.jobTimestamps.map(timestamp => {
        return new Date(timestamp).toDateString();
      })
    )].map(dateString => {
      return new Date(dateString);
    })
  }

  get startDate () {
    return new Date(new Date(this.startTimestamp).toDateString());
  }

  get endDate () {
    return new Date(new Date(this.endTimestamp).toDateString());
  } 
}

export { BranchSummary }