var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCardBody",
    [
      _c("CDataTable", {
        attrs: {
          items: _vm.tableItems,
          fields: _vm.fields,
          "items-per-page": 20,
          hover: "",
          pagination: "",
          "table-filter": {
            external: false,
            lazy: false,
            label: "Search",
            placeholder: _vm.searchPlaceHolder
          },
          cleaner: "",
          "clickable-rows": ""
        },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("ReportPerTruckScopeBranchCard", {
                  attrs: { summary: item },
                  on: { changeToTruckView: _vm.changeToTruckView }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }