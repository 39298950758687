<template>
  <CCard class="my-1 pt-1 mx-1" id="card-per-truck">
    <CCardBody class="mt-0 pt-1">
      <CRow>
        <CCol col="3">
          <td>
            <TruckInfoCard :device="deviceId" name="" :showStatus="false">
            </TruckInfoCard>
          </td>
        </CCol>
        <CCol col='4'>
          <div id="active-day">
            <span> <span class="number"> {{activeDates.length}} </span> Active Days </span>
          </div>
          <div id="job-count">
            <label> Jobs Completed: </label>
            <span class="number"> {{jobCounts}} </span>
          </div>
          <div id="violation-count" hidden>
            <label> Total # of Violations: </label>
            <span class="number"> {{violationCounts}} </span>
          </div>
        </CCol>
        <CCol col="3">
           <CScoreCard
              class="p-0"
              score="good"
              title="Safety Score"
            /> <!-- h_todo fix the scorecard-->
        </CCol>
        <CCol col="2">
          <CButton size="" color="info" class="" @click="changeToTruckView">
            Go to Truck {{deviceId}}
          </CButton>
        </CCol>
        <!-- <CCol col="4" class="h-50 my-2">
            <CScoreCard
              class="h-50 my-2"
              :score="item.score"
              title="Safety Score"
            />
          </CCol> -->
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import { TruckSummary } from "@/lib/rule_lib/truck_summary";
import CScoreCard from "@/components/reports/CScoreCard";
import TruckInfoCard from "@/views/app/TruckInfoCard";

export default {
  name: "ReportPerTruckScopeBranchCard",
  components: {
    CScoreCard,
    TruckInfoCard
  },
  props: {
    summary: TruckSummary,
  },
  data() {
    return {};
  },
  computed: {
    deviceId () {
      return this.summary.deviceId;
    },

    // truckId () {
    //   return this.summary.truckId;
    // },

    activeDates () {
      return this.summary.activeDates;
    },

    jobCounts () {
      return this.summary.jobCounts;
    },

    violationCounts () {
      return this.summary.violationCounts;
    }
  },
  methods: {
    changeToTruckView() {
      this.$emit("changeToTruckView", this.summary.deviceId);
    }
  }
};
</script>


<style scoped>

#card-per-truck .number {
  font-size: 25px;
}

</style>

