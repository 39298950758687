<template>
  
    <div class="datetime-container mb-1 ml-1">
      <v-date-picker ref="datePicker" v-model="range" mode="date" 
      :columns="$screens({ default: 1, sm: 2 })"
      :step="1"
      :max-date="maxDate"
      v-on:input="onChange"
      is-range>
        <template v-slot="{ inputValue, inputEvents }">
          <div class="flex justify-center items-center">
            From:
            
            
            <input
              size=""
              
              :value="inputValue.start"
              v-on="inputEvents.start"
              @change="setDateFilter"
              class="px-2 py-1 w-32 rounded disabled focus:outline-none focus:border-indigo-300"
           />
            
            <!--template #input>
              <masked-input
                type="text"
                name="date"
                class="form-control"
                v-model="range.start"
                :mask="[/0|1|2|3/, /\d/, '/', /0|1|2|3/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]"
                :guide="true"
                placeholderChar="_"
                :showMask="true"
                :keepCharPositions="true"
              />
            </template-->
            
            
            To:
            <input
              size="sm"
               
              :value="inputValue.end"
              v-on="inputEvents.end"
              @changed="e => setDateFilter(e, 'end')"
              class="px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
            />
          </div>
        </template>
      </v-date-picker>
    <slot name="button">
      <CButton
        v-if="button"
        color="info"
        square
        size="sm"
        class="m-0 p-1"
        @click="update()"
        v-c-tooltip="'Refresh Transactions'"
      >
        <div style="">
          <CIcon name="cil-sync" class="h-100 m-0 p-0" />
          <span class="color:white pull-right"></span>
        </div>
      </CButton>
     </slot>
     
    </div>
  
</template>

<script>
import { DatePicker } from "v-calendar";
const MS_PER_DAY = 86400000;

export default {
  name: "CDatePicker",
  props: {
      button: {
          type: Boolean,
          default: false, 
      },
      start: {
        type: String | Number, 
      },
      end: String | Number,
      maxDate: String | Number,  
      /*
    range: {
      type: Object,
      default: () => {
        return {
          start: new Date(),
          end: new Date(Date.now() - MS_PER_DAY * 7)
        };
      }
    }
    */
  },
  components: {
    "v-date-picker": DatePicker
  },
  data() {
    return {

      relativeTimeSelected: "",
      
      range: {
        start: new Date(),
        end: new Date(Date.now() - MS_PER_DAY * 30)
      },
      

      datePickerKey: 0,
      dateTime_ab: true
    };
  },
  computed:{
      startDate: {
        get(){ return this.range.start }
      },
      endDate: {
        get(){
          return this.range.end;
        }
      } 
  },
  mounted(){

  }, 
  methods: {
    formatDate(date){
      console.log(date);
      let sp = date.split("/"); 
      let temp = {
        yr: sp[2], 
        mo: sp[1], 
        dd: sp[0],
      }

      return `${temp.yr}-${temp.mo}-${temp.dd}`; 
    },
    update() {
      this.datePickerKey += 1;
      console.log(this.$refs.datePicker.updateValue(this.range));
      this.$refs.datePicker.$forceUpdate();
      this.$forceUpdate();
    },
    onChange(e){
        console.log(e); 
        console.log(this.start); 
        console.log(this.end,this.range); 

        //this.start = this.range.start.getTime();
        //this.end = this.range.end.getTime();
        
        
        this.$emit("update:start", this.range.start.getTime());
        this.$emit("update:end", this.range.end.getTime());
        this.$emit("update", this.range); 
    },
    handleClick(item) {
      // alert("You're about to download some .CSV");
    },
    setDateFilter(e, end) {
        console.log(e); 
      if (end) {
        this.endDate = new Date(e.target.value).getTime();
      } else {
        this.startDate = new Date(e.target.value).getTime();
      }
    }
  }
};
</script>