<template>
  <div>
    <CRow>
      <CCol lg="12" color="danger" class="">
        <CRow>
          <CCol sm="12">
            <CCard :accent-color="accentColor">
              <CCardHeader
                :color="backColor"
                text-color="light"
                class="standard-card-header"
              >
                <span> Truck Report </span>
              </CCardHeader>
              <CCardBody>
                <CRow id="date-picker">
                  <CCol>
                    <CDatePicker2 
                      :start="start"
                      :end="end"
                      @update="updateDateRange"
                      :max-date="new Date()"
                    /> 
                  </CCol>
                </CRow>
                <CRow v-if="branchSummary" id="summary">
                  <CCol>
                    <SummaryReportScopeBranch
                      :summary="branchSummary"
                    />
                  </CCol>
                </CRow>

                <CRow id="list-table">
                  <CCol>
                    <ReportTableScopeBranch
                      @changeToTruckView="changeToTruckView"
                      :summaries="truckSummaries"
                    />
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>


<script>
import ReportTableScopeBranch from "@/components/tables/ReportTableScopeBranch";
import ThemeMixin from "@/mixins/ThemeMixin";
import { BranchSummary } from '../../../lib/rule_lib/branch_summary';
import { TruckSummary } from '../../../lib/rule_lib/truck_summary';
import CDatePicker2 from "@/components/base/CDatePicker";
import SummaryReportScopeBranch from '@/components/reports/SummaryReportScopeBranch';
import { JobOperation } from "@/lib/rule_lib/job_operation";
// import { DatePicker } from "v-calendar";




function withinDateRange(time, startTime, endTime) {
  return time > startTime && time < endTime;
}

export default {
  name: "ReportsListBranchView",
  components: {
    ReportTableScopeBranch,
    SummaryReportScopeBranch,
    CDatePicker2,
    // DatePicker,
  },
  data() {
    return {
      // activeUID: null,
    };
  },
  mixins: [ThemeMixin],
  computed: {
    start () {
      return Number(this.$route.query.start) || new Date() - 12096e5;
    },

    end () {
      return Number(this.$route.query.end) || new Date() - 1 + 1;
    },

    dateRange () {
      return {
        start: this.start,
        end: this.end,
      }
    },

    jobs() {
      let output = this.$store.getters.reports.map((item) => {
        return { ...item };
      }).filter(job => {
        return withinDateRange(job.timestamp, this.start, this.end);
      })
      return output;
    },

    jobOperations () {
      return this.jobs.map(job => {
        return new JobOperation({
          id: job.id,
          uid: job.uid,
          truckId: job.truck,
          deviceId: job.device,
          startTimestamp: job.start,
          endTimestamp: job.end,
          score: job.score,
        });
      })
    },

    truckBins() {
      return new Set(
        this.jobOperations.map(jobOperation => {
          return jobOperation.deviceId;
        })
      );
    },

    jobsInTruckBins () {
      let output = {};
      this.truckBins.forEach(truckBin => {
        output[truckBin] = [];
      });
      this.jobOperations.forEach(jobOperation => {
        let truckBin = jobOperation.deviceId;
        output[truckBin].push(jobOperation);
      });
      return output;
    },

    branchSummary () {
      return new BranchSummary(this.jobOperations, this.branchId, this.start, this.end);
    },

    truckSummaries () {
      let output = {};
      Object.keys(this.jobsInTruckBins).forEach(deviceId => {
        let truckJobs = this.jobsInTruckBins[deviceId];
        let truckId = truckJobs[0]? truckJobs.truckId : "";
        output[deviceId] = new TruckSummary(deviceId, truckId, truckJobs, this.start, this.end);
      })
      return output;
    },

    branchId () {
      return this.$store.getters.selectedBranch? this.$store.getters.selectedBranch.branch_id : null;
    }
  },

  async created() {
    console.log(`reportSuperListView::created truck: ${this.filteringDeviceId} date: ${this.filteringDate}`);
    await this.loadReports();
  },

  // h_todo: to test if it is kept alive
  destroyed () {
    console.log(`reportSuperListView::destroyed truck: ${this.filteringDeviceId} date: ${this.filteringDate}`);
  },
  methods: {
    async refresh() {
      await this.loadReports();
    },
    async loadReports() {
      try {
        let params = {
          end: Date.now()
        };
        await this.$app.loadReports(params);
      } catch (err) {
        console.log(err);
      }
    },

    // setReportToActive (uid) {
    //   if (this.activeUID == uid) {
    //     this.activeUID = null;
    //   } else {
    //     this.activeUID = uid;
    //   }
    // },

    changeToTruckView (id) {
      console.log('changeToTruckView');
      this.$router.push({
        path: '/reports/list/truck', 
        query: { 
          device: id,
          start: this.start,
          end: this.end, 
        }
      })
    },

    updateDateRange (range) {
      this.$router.push({ 
        path: '/reports/list/branch', 
        query: {
          start: range.start - 1 + 1, // cast to timestamp
          end: range.end - 1 + 1, // cast to timestamp
        }
      })
    }
  },

  watch: {
    async branchId (val) {
      await this.loadReports({
        branch_id: val, // h_todo: api allow branch filter
      }); 
    }
  }
};
</script>
